import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>HSS OMG</h1>
      <iframe src="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfjNixuiCYCUm3zibGiUW4-0lVRXDVHR_c30DuMfXNhZwhR1g/viewform?embedded=true" width="640" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

      {/* Floating Shapes */}
      <div className="floating-shape circle1"></div>
      <div className="floating-shape circle2"></div>
      <div className="floating-shape circle3"></div>
      <div className="floating-shape square1"></div>
      <div className="floating-shape square2"></div>
      <div className="floating-shape triangle1"></div>
      <div className="floating-shape triangle2"></div>
      
      {/* New Shapes */}
      <div className="floating-shape circle4"></div>
      <div className="floating-shape circle5"></div>
      <div className="floating-shape square3"></div>
      <div className="floating-shape square4"></div>
      <div className="floating-shape triangle3"></div>
      <div className="floating-shape triangle4"></div>

      {/* Additional Shapes */}
      <div className="floating-shape circle6"></div>
      <div className="floating-shape square5"></div>
      <div className="floating-shape circle7"></div>
      <div className="floating-shape square6"></div>
      <div className="floating-shape triangle5"></div>
      <div className="floating-shape triangle6"></div>
      <div className="floating-shape circle8"></div>
      <div className="floating-shape circle9"></div>
      <div className="floating-shape square7"></div>
      <div className="floating-shape triangle7"></div>
      <div className="floating-shape triangle8"></div>
    </div>
  );
}

export default App;

